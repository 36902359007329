<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-25">
            <FormSelect
              v-model="filters.status"
              :options="commStatuses"
              label="Status"
              icon="donut_large"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <AgentsSelect
              v-model="filters.agent_id"
              :disabled="userIsAgent && !userIsAgentsManager"
              :agent-groups="agentGroupsManagerIds"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <FormDate
              v-model="filters.from"
              name="from"
              label="Paid Date From..."
            />
          </div>
          <div class="md-layout-item md-size-25">
            <FormDate
              v-model="filters.to"
              name="to"
              label="Paid Date To..."
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="getCommissions()"
            >
              Filter
            </md-button>
            <md-button
              class="md-primary md-sm w-100"
              @click="downloadFiltered()"
            >
              Download XLS
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="d-inline-block pull-right">
              <md-switch
                v-model="filters.show_fully_paid"
                @change="getCommissions()"
              >
                Show Fully Paid
              </md-switch>
            </div>
          </div>

          <div class="md-layout-item md-size-100 mt-4">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              hidden-filters
              hidden-footer
              checkeable
              @checked="onCheck"
              @changed="getCommissions"
              @onShowDetails="onShowDetails"
              @goToProfile="goToProfile"
            />

            <div
              v-if="
                !loading && (!vTable.values.data || !vTable.values.data.length)
              "
              class="p-4 text-center"
            >
              There is no Commissions to show
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div
      v-if="submitResume.length"
      class="md-layout justify-content-end"
    >
      <div class="md-layout-item md-size-33 md-small-size-100 p-0">
        <md-card class="mt-0">
          <md-card-header>
            <h5 class="m-0 mt-2 text-center">
              <b>COMMISSIONS TO SUBMIT</b>
            </h5>
          </md-card-header>
          <md-card-content>
            <md-table>
              <md-table-row>
                <md-table-head style="width: 70%">
                  Student
                </md-table-head>
                <md-table-head>Commission</md-table-head>
              </md-table-row>
              <md-table-row
                v-for="t of submitResume"
                :key="t.student_number"
              >
                <md-table-cell>
                  <small style="opacity: 0.5">({{ t.student_number }})</small>
                  {{ t.full_name }}
                </md-table-cell>
                <md-table-cell>
                  €{{ parseFloat(t.commission).toFixed(2) }}
                </md-table-cell>
              </md-table-row>
              <md-table-row class="font-weight-bold">
                <md-table-cell class="text-right">
                  Total
                </md-table-cell>
                <md-table-cell>
                  €{{
                    submitResume
                      .reduce((a, b) => a + b.commission, 0)
                      .toFixed(2)
                  }}
                </md-table-cell>
              </md-table-row>
            </md-table>

            <md-button
              class="md-success md-block"
              @click="downloadReport"
            >
              Submit Commision List
            </md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <CommissionsDetails
      v-if="showDetails"
      :item="selItem"
      @onCheck="onCheck"
      @onReject="getCommissions"
      @onDelete="getCommissions"
      @close="closeDetails"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { AgentsSelect } from '@/components/Inputs/selects';
import { FormDate, FormSelect } from '@/components/Inputs';

import moment from 'moment';
import downloadFile from '@/utils/downloadFile';
import CommissionsDetails from '@/components/Commissions/CommissionsDetails.vue';

export default {
  components: {
    VueTable,
    AgentsSelect,
    FormDate,
    CommissionsDetails,
    FormSelect,
  },
  data() {
    return {
      loading: false,
      vTable: {
        headers: [
          {
            title: 'agent',
          },
          {
            title: 'student_number',
            mask: 'student id',
          },
          {
            title: 'full_name',
            mask: 'full name',
          },
          {
            title: 'payment_date',
            mask: 'payment date',
            dateFormat: true,
          },
          {
            title: 'instalment',
          },
          {
            title: 'payment_method',
            mask: 'payment method',
          },
          {
            title: 'income_total',
            mask: 'amount approved',
          },
          {
            title: 'paid_amount',
            mask: 'total received',
          },
          {
            title: 'package_price',
            mask: 'package price',
          },
          {
            title: 'commission',
          },
          {
            title: 'commission_rate',
            mask: 'commission rate',
            after: '%',
          },
          {
            title: 'status',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Go To Profile',
            callback: 'goToProfile',
            icon: 'people',
          },
        ],
        values: {},
      },
      filters: {
        agent_id: null,
        from: moment().subtract(31, 'days'),
        to: moment(),
        show_fully_paid: false,
        status: 'ALL',
        show_submitted: false,
      },
      commStatuses: [
        { id: 'ALL', name: 'ALL' },
        { id: 'NON COMMISSIONABLE', name: 'NON COMMISSIONABLE' },
        { id: 'UNSUBMITTED', name: 'UNSUBMITTED' },
        { id: 'UNPAID', name: 'UNPAID' },
        { id: 'PAID', name: 'PAID' },
        { id: 'REJECTED', name: 'REJECTED' },
      ],
      showDetails: false,
      selItem: null,
      payment_method_id: null,
    };
  },
  computed: {
    submitResume() {
      if (!this.vTable.values?.data) return [];

      const rawData = this.vTable.values.data
        .filter((x) => x.checked)
        .map((x) => ({
          income_id: x.income_id,
          student_number: x.student_number,
          full_name: x.full_name,
          commission: x.commission,
        }));

      return Object.values(
        rawData.reduce((a, b) => {
          if (a[b.student_number]) {
            a[b.student_number].commission += parseFloat(b.commission);
          } else {
            a[b.student_number] = {
              income_id: b.income_id,
              student_number: b.student_number,
              full_name: b.full_name,
              commission: parseFloat(b.commission),
            };
          }

          return a;
        }, {}),
      );
    },
  },
  mounted() {
    if (this.userIsAgent && !this.userIsAgentsManager) {
      this.filters.agent_id = this.user.user_id;
    }

    this.$refs.vtable.init();
  },
  methods: {
    getCommissions() {
      this.loading = true;
      this.request(
        'agents/commissions',
        'get',
        { ...this.filters, per_page: 1000 },
        ({ data }) => {
          this.vTable.values = {
            current_page: 1,
            from: 1,
            to: 1,
            last_page: 1,
            per_page: 1,
            total: 1,
            data: data.map((x) => {
              x.checked = false;
              x.checkeable = ['UNSUBMITTED'].includes(x.status);
              return x;
            }),
          };
        },
        () => {
          this.loading = false;
        },
      );
    },
    downloadReport() {
      this.loading = true;
      const ids = this.vTable.values.data
        .filter((x) => x.checked)
        .map((x) => x.income_id);

      this.request(
        'agents/commissions/submit',
        'download',
        { income_ids: ids, ...this.filters },
        ({ data }) => {
          const filename = `submitted_commissions_${moment().format(
            'YYYYMMDD-HHmmss',
          )}.xlsx`;
          downloadFile(data, filename);
          this.getCommissions();
        },
        () => {
          this.loading = false;
        },
      );
    },
    downloadFiltered() {
      this.loading = true;
      const from = moment(this.filters.from).format('YYYY_MM-DD');
      const to = moment(this.filters.to).format('YYYY_MM-DD');

      this.request(
        'agents/commissions',
        'download',
        { download: true, ...this.filters },
        ({ data }) => {
          const filename = `commission_list_${from}-to-${to}.xlsx`;
          downloadFile(data, filename);
          this.getCommissions();
        },
        () => {
          this.loading = false;
        },
      );
    },
    onShowDetails(item) {
      this.selItem = item;
      this.showDetails = true;
    },
    closeDetails() {
      this.selItem = null;
      this.showDetails = false;
    },
    onCheck(e) {
      if (!e.all) {
        if (e.index) {
          this.vTable.values.data[e.index].checked = e.value;
        } else if (e.id) {
          const item = this.vTable.values.data.find(
            (x) => x.income_id === e.id,
          );
          item.checked = e.value;
        }
      } else {
        this.vTable.values.data.map((x) => {
          if (x.checkeable) x.checked = e.value;
          return x;
        });
      }
    },
    goToProfile(item, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: item.student_id },
      }, isMiddleClick);
    },
  },
};
</script>

<style scoped lang="scss">
.method-cell {
  padding: 0;

  ::v-deep .md-field {
    padding-bottom: 0 !important;

    &::after,
    &::before {
      bottom: 0 !important;
    }
  }
}
</style>
