<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      Details
    </template>
    <template slot="body">
      <div
        v-if="item.commission_comments"
        class="alert alert-danger text-center"
      >
        <p><b>COMMISSION REJECTION REASON</b></p>
        <p class="small">
          {{ item.commission_comments }}
        </p>

        <hr
          class="my-1 mx-5"
          style="opacity: .5;"
        >

        <p
          class="small mt-3 mb-1"
          style="opacity: .5;"
        >
          Please delete the submitted commission to submit it again with the corrections
        </p>
      </div>
      <div class="commission-details-head">
        <FormText
          v-model="item.student_number"
          label="Student Number"
          disabled
          class="grid-1"
        />
        <FormText
          v-model="item.full_name"
          label="Student Name"
          disabled
          class="grid-2"
        />

        <FormText
          v-model="item.instalment"
          label="Instalment"
          disabled
          class="grid-3"
        />
        <FormText
          v-model="item.payment_date"
          label="Payment Date"
          disabled
          class="grid-4"
        />
        <FormText
          v-model="item.income_total"
          label="Payment Amount"
          disabled
          class="grid-5"
        />
        <FormText
          v-model="item.payment_method"
          label="Payment Method"
          disabled
          class="grid-6"
        />
        <FormText
          v-model="item.visa_type"
          label="Visa Type"
          disabled
          class="grid-7"
        />

        <FormText
          v-model="item.income_id"
          label="Income ID"
          disabled
          class="grid-15"
        />
        <FormText
          v-model="item.agent"
          label="Agent"
          disabled
          class="grid-8"
        />
        <FormText
          v-model="item.commission"
          label="Commission"
          disabled
          class="grid-9"
        />
        <FormText
          v-model="item.agent_commission"
          label="Commission Type"
          disabled
          class="grid-10"
        />
        <FormText
          v-model="item.sales_source"
          label="Sales Source"
          disabled
          class="grid-11"
        />

        <FormText
          v-model="item.payment_comments"
          label="Payment Comments"
          textarena
          disabled
          class="grid-12"
        />
        <FormText
          v-model="item.receipt_comments"
          label="Student Receipt Comments"
          disabled
          class="grid-13"
        />
        <FormText
          v-model="item.payment_remark"
          label="Payment Remark"
          disabled
          class="grid-14"
        />
      </div>
      <div
        v-if="item.instalment !== 'Income'"
        class="commission-details-body"
      >
        <table class="table inner-borders">
          <tr class="">
            <th>Package Item</th>
            <th>Price</th>
            <th>Commissionable</th>
          </tr>
          <tr
            v-for="i of item.items"
            :key="i.package_package_item_id"
          >
            <td>{{ i.package_item.name }}</td>
            <td>{{ i.price }}</td>
            <td>{{ i.package_item.commissionable ? 'YES' : 'NO' }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <b>Package Price</b>
              {{ item.package_price }}
            </td>
          </tr>
        </table>
        <table class="table inner-borders mt-5">
          <tr>
            <th>Payment Date</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th>Commissionable</th>
          </tr>
          <tr
            v-for="p of item.payments"
            :key="p.package_payment_id"
          >
            <td>{{ p.final_payment_date }}</td>
            <td>{{ p.total }}</td>
            <td>{{ p.payment_method.name }}</td>
            <td>{{ p.payment_method.commissionable ? 'YES' : 'NO' }}</td>
          </tr>
          <tr>
            <td colspan="4">
              <b>Total Paid</b>
              {{ item.paid_amount }}
            </td>
          </tr>
        </table>
      </div>
    </template>
    <template slot="footer">
      <md-button
        v-if="!canConfirm && item.commission_comments"
        class="md-danger"
        @click="onDelete"
      >
        <md-icon>delete</md-icon>
        DELETE
      </md-button>

      <md-button
        v-if="canConfirm"
        class="md-success"
        @click="onCheck"
      >
        <md-icon>check</md-icon>
        CHECK
      </md-button>
      <md-button
        v-if="canConfirm"
        class="md-danger"
        @click="onReject"
      >
        <md-icon>close</md-icon>
        REJECT
      </md-button>
      <md-button
        class="md-primary"
        @click="close"
      >
        Close
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText } from '@/components/Inputs';

export default {
  components: { FormText },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    canConfirm() {
      return this.$route.name === 'FinanceCommissions' && this.item.status === 'UNPAID';
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    onCheck() {
      this.$emit('onCheck', { value: true, id: this.item.income_id });
      this.close();
    },
    onDelete() {
      this.fireConfirm(
        'Deleting Rejected Commission',
        'After this, you have to submit the commission again',
      ).then(() => {
        this.request(`agents/commissions/${this.item.income_id}`, 'delete', null, (data) => {
          this.fireSuccess(data);
          this.$emit('onDelete');
          this.close();
        });
      });
    },
    onReject() {
      this.fireModal({
        title: 'Rejecting Commission',
        html: 'Please complete with the reason for Rejection.',
        input: 'text',
        inputPlaceholder: 'Rejection Reason',
      }).then(({ value }) => {
        if (value !== undefined) {
          if (!value) {
            this.fireError('Please complete the reason');
          } else {
            this.request(`agents/commissions/${this.item.income_id}/reject`, 'put', {
              comments: value,
            }, (data) => {
              this.fireSuccess(data);
              this.$emit('onReject');
              this.close();
            });
          }
        }
      });
    },
  },
};
</script>
<style></style>
